import React, { useEffect, useState } from "react";
import DocumentNav from "./DocumentNav";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import DocumentDetailContent from "./DocumentDetailContent";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useStore from "../../Store";


const DocumentDetail = () => {
    const navigate = useNavigate();
    const { setSecondaryKeywords } = useStore()


    const [doc, setDoc] = useState([])
    let { id } = useParams();
    useEffect(() => {
        const getDocument = async () => {
            try {
                const response = await api.get(
                    `/documents/${id}/`,
                );
                setDoc(response.data)
                setSecondaryKeywords(response.data.secondary_keywords)
               

            } catch (err) {
                navigate("/not-found")
                console.error("Something went wrong. Error: ", err)
            }
        }

        getDocument();
    }, []);
    return (
        <>
            <DocumentNav name={doc.name} id={id} projectId={doc.project}  toneValue={doc.tone} target={doc.project_target_audiences} docStatus={doc.doc_status} docData={doc.document_content|| {} }/>
            <DocumentDetailContent doc={doc.document_content}/>
        </>
    )
};

export default DocumentDetail;
