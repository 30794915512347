import React, { useEffect, useRef, useState } from "react";
import "./Document.css";
import BackArrow from "../../assets/backarrow.png";
import Delete from "../../assets/delete.svg";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "antd";
import { Link, useParams } from "react-router-dom";
import api from "../../utils/api";
import Layout from "../Layout/Layout";
import ProjectNav from "../projects/ProjectNav";
import EditModal from "../projects/EditModal";
import CreateDocumentModal from "./CreateDocumentModal";
import tick from "../../assets/tick.svg";
import vector from "../../assets/vector.svg";
import { toast } from "react-toastify";

const DocumentsList = () => {
    const { id } = useParams();
    const [ProjectDocuments, setProjectDocuments] = useState([]);
    const [docModalOpen, setDocModalOpen] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectLogo, setProjectLogo] = useState('');
    const [openStatusId, setOpenStatusId] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState({}); // Object to manage delete dropdown state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState({});
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    useEffect(() => {
        const getDocuments = async () => {
            try {
                const response = await api.get(`/project-documents/${id}/`);
                setProjectDocuments(response.data.documents);
                setProjectName(response.data.project_name);
                setProjectLogo(response.data.project_logo_url);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        getDocuments();
    }, [id]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenStatusId(null);
            setDeleteOpen({});
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredDocuments = ProjectDocuments.filter((doc) =>
        doc.name && doc.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const showModal = () => {
        setDocModalOpen(true);
    };

    const handleStatusClick = (docId) => {
        setOpenStatusId(prev => (prev === docId ? null : docId));
    };

    const handleDeleteClick = (docId) => {
        setDeleteOpen(prev => ({
            ...prev,
            [docId]: !prev[docId],
        }));
    };

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this document?");
        if (!isConfirmed) return;

        try {
            await api.delete(`/documents/${id}/`);
            const updatedDocuments = ProjectDocuments.filter(doc => doc.id !== id);
            setProjectDocuments(updatedDocuments);
            setDeleteOpen((prev) => ({ ...prev, [id]: false })); // Close dropdown after deletion
            toast.success("Document deleted successfully");
        } catch (err) {
            console.error("Document deleting failed:", err);
            toast.error("Error deleting Document. Please try again.");
        }
    };

    const statuses = [
        { label: "Done", value: "Done" },
        { label: "In Progress", value: "In Progress" },
        { label: "Waiting for Approval", value: "Waiting for Approval" },
        { label: "Published", value: "Published" },
    ];

    const toggleActive = async (value, id) => {
        setSelectedStatus(prev => ({ ...prev, [id]: value }));
        try {
            await api.put(`/documents/${id}/`, { doc_status: value });
            toast.success("Document Status Updated Successfully");
        } catch (error) {
            console.error("Error updating Status of Document", error);
            toast.error("Error updating Status of Document");
        }
        setOpenStatusId(null);
    };

    return (
        <Layout>
            <ProjectNav logo_url={projectLogo} projectName={projectName} setIsModalOpen={setIsModalOpen} setIsOpen={setIsOpen} />
            <div className="doc-Container">
                <div className="doc-main-heading">
                    <Link to={`/project/${id}`}>
                        <div className="backarrow">
                            <img src={BackArrow} alt="Back" />
                        </div>
                    </Link>
                    <span className="doc-main-heading-content">Documents</span>
                </div>
                <Input
                    placeholder="Search a Document"
                    className="doc-search-input"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    suffix={<AiOutlineSearch className="doc-search-icon" />}
                />

                {filteredDocuments.length > 0 ? (
                    filteredDocuments.map((item) => {
                        const seoScore = item.seo_score ? `${item.seo_score}%` : "-";
                        const isDeleteOpen = deleteOpen[item.id] || false;
                        const statusValue = selectedStatus[item.id] || item.doc_status;

                        return (
                            <div key={item.id} className="doc-Content">
                                <div>
                                    <Link to={`/document/${item.id}/`}>
                                        <h3 className="doc-title">{item.name}</h3>
                                    </Link>
                                </div>
                                <div>
                                    <h5 className="doc-type">{item.doc_type}</h5>
                                </div>
                                <div>
                                    <span className="doc-type">SEO Score:</span>
                                    <span className="doc-seo">{seoScore}</span>
                                </div>
                                <div className="doc-status-parent" onClick={() => handleStatusClick(item.id)}>
                                    <h3 className={`title status-dropdown ${statusValue.toLowerCase()}`}>
                                        {statusValue}
                                        <img style={{ cursor: "pointer" }} src={vector} alt="" />
                                    </h3>
                                    {openStatusId === item.id && (
                                        <div ref={dropdownRef} className="publish">
                                            {statuses.map((status) => {
                                                const isActive = statusValue === status.value;
                                                return (
                                                    <div
                                                        key={status.value}
                                                        className={`${isActive ? "publish-div" : "publish-div1"}`}
                                                        onClick={() => toggleActive(status.value, item.id)}
                                                    >
                                                        <div className="publish-flex">
                                                            <div className="publish-p1">{status.label}</div>
                                                            {isActive && <img src={tick} alt="Tick" />}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                                <div className="doc-type">{item.date}</div>
                                <div className="delete-container">
                                    <div>
                                        <PiDotsThreeOutlineFill className="doc-detail" onClick={() => handleDeleteClick(item.id)} />
                                    </div>
                                    {isDeleteOpen && (
                                        <div ref={dropdownRef} className="del-btn">
                                            <div className="del-bg" onClick={() => handleDelete(item.id)}>
                                                <div className="del-flex">
                                                    <img src={Delete} alt="" />
                                                    <div className="del-text">Delete</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="img_round_main_dashboard" style={{ height: "50%" }}>
                        <p>There are no Documents yet, Get started by creating a new Document</p>
                        <button
                            className="createaccountbtn"
                            style={{ backgroundColor: "#262626", color: "white", cursor: "pointer" }}
                            onClick={showModal}
                        >
                            Create Document
                        </button>
                    </div>
                )}
            </div>
            <CreateDocumentModal docModalOpen={docModalOpen} setDocModalOpen={setDocModalOpen} id={id} />
            <EditModal open={isModalOpen} setOpen={setIsModalOpen} />
        </Layout>
    );
};

export default DocumentsList;
